import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import "./checkRepo.scss"
import { loadRepo } from '../../api/apiCall'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const options = [
    { value: 'Javascript', label: 'Javascript' },
    { value: 'Python', label: 'Python' },
  ]
  

const CheckRepo = () => {
const [selectValue, setSelectValue] = useState(options[1]);
const [repoText, setRepoText] = useState("https://github.com/akashkumarbtc/demo-project.git")
const [isRepoAccesable, setIsRepoAccesable] = useState(false)
const [loading, setLoading] = useState(false)
const [isChecked, setChecked] = useState(true);

useEffect(() => {
  // Perform this only once after the initial render
  if (isChecked) {
    localStorage.setItem("repoId", "11fef45d-d548-4c16-a671-d655a8ba3e97");
  } else {
    localStorage.removeItem("repoId");
  }
}, []);

const handleCheckboxChange = () => {
  setChecked(!isChecked);
  setRepoText(isChecked ? "" : "https://github.com/akashkumarbtc/demo-project.git");
  
  isChecked
    ? localStorage.removeItem("repoId")
    : localStorage.setItem("repoId", "11fef45d-d548-4c16-a671-d655a8ba3e97");
};

const handleLoadRepo = async () => {
    if(!repoText.trim()){
        toast.warn('Please add the repo url', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
            return;
    }
    if(!loading){
      setLoading(true);
      setIsRepoAccesable(false)
      const id = isChecked
          ? localStorage.getItem("repoId")
          : uuidv4();

      const payload = {
        programming_language: selectValue.value,
        uuid: id,
        repo_url: repoText,
      };

      const res = await loadRepo(payload);

      if(res.status === 200){
        localStorage.setItem("repoId", id);
        localStorage.setItem("programming_language", selectValue.value);
        console.log(res, res.status);
       
        toast.success("Hurray! Repository is accessible", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(()=> {
            setIsRepoAccesable(true)
        }, 5000)
      }
      setLoading(false);
    }

}
  return (
    <div className="container">
      
      <div className="container-inner">
      <div className="container-inner-heading">
        <div className="container-inner-heading-row">
          <span className="container-inner-heading-title-heading">Talk-to-Code</span>
          <label className="container-inner-heading-row-label">
          <input
            type="checkbox"
            checked={isChecked} 
            onChange={handleCheckboxChange} 
          />
          Demo
          </label>
        </div>
          <span className="container-inner-head-title">
          <p>
  To include your own GitHub repository <strong>(with public access)</strong>, deselect the demo option in the top-right corner.<br />
  Then, input your repository name and choose the primary programming language.
</p>

          </span>

      </div>
        <div className="container-inner-head">
        
          <Select
            options={options}
            value={selectValue}
            className="container-inner-head-select"
            onChange={(val) => setSelectValue(val)}
          />
        </div>

        <textarea
          className="container-inner-textInput"
          placeholder="Enter your github repo here...
https://github.com/akashkumarbtc/generative-ai.git"
          value={repoText}
          disabled={loading}
          onChange={(e) => setRepoText(e.target.value)}
        />

        <div className="container-inner-button">
          <span
            className={isRepoAccesable ? "container-inner-button-access-disable" : "container-inner-button-access"}
            onClick={handleLoadRepo}
          >
            {loading ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <span> Check Repo Access</span>
            )}
          </span>
          {isRepoAccesable && (
            <Link to="/chatbot" className='linkBtn'>
              <span className="container-inner-button-access">
                <span> Talk to Code</span>
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckRepo