import React from "react";
import UserIcon from "../Images/profile-boy-icon.svg"
export default function UserMessage({ text }) {
  return (
    <div className="message-container">
      <div className="user-message">
      <img src={UserIcon} className="icon" alt="botIcon" />
        <span>{text}</span>
      </div>
    </div>
  );
}
