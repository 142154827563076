import React from 'react'
import "./header.scss"
import { Link } from 'react-router-dom';

const Header = ({showLogout, logoutFun, isHomePage}) => {

  const scrolToElement = (elId) => {

    const el = document.getElementById(elId)
    el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  }
  return (
    <div className="header">
     { showLogout ? 
     
     <span className="header-text">Talk To Code</span>
     : <Link to="/">
        <img
          className="header-img"
          src={require("../Images/blueticklogo.png")}
          alt="logo"
        />
      </Link>}
     {isHomePage && <div className='header-link'>
        <span className='header-link-btn' onClick={()=> scrolToElement("guide")}>Quick Guide</span>
        <span className='header-link-btn' onClick={()=> scrolToElement("feature")}>Feature</span>
      </div>}
      {showLogout && (
        <span className="header-logout" onClick={logoutFun}>
          <i class="fa fa-sign-out" aria-hidden="true"></i>
        </span>
        
      )}
    </div>
  );
}

export default Header