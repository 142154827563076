import React from 'react'
import "./footer.scss"



const Footer = () => {
  return (
      <div className="footer">
          <ul className="footer-text">
            <li>
              <a
                href="https://www.bluetickconsultants.com/contact-us.html"
                style={{ color: "#0257AB" }}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="https://www.bluetickconsultants.com/about-us.html"
                style={{ color: "#0257AB" }}
              >
                About us
              </a>
            </li>
            <li>
              <a href="terms_of_services.html" style={{ color: "#0257AB" }}>
                Terms of Services
              </a>
            </li>
            <li>
              <a href="privacy_policy.html" style={{ color: "#0257AB" }}>
                Privacy Policy
              </a>
            </li>
          </ul>
          <div className='footer-container'>
            <ul className="footer-container-icon">
              <li>
                <a
                  href="https://www.facebook.com/bluetickconsultants/"
                  target="blank"
                >
                  <svg
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                      fill="#0270D7"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/BluetickConsult" target="blank">
                  <svg
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                      fill="#0270D7"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bluetick-consultants/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 72 72"
                    id="linkedin"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="72" height="72" fill="#117EB8" rx="4"></rect>
                      <path
                        fill="#FFF"
                        d="M13.139 27.848h9.623V58.81h-9.623V27.848zm4.813-15.391c3.077 0 5.577 2.5 5.577 5.577 0 3.08-2.5 5.581-5.577 5.581a5.58 5.58 0 1 1 0-11.158zm10.846 15.39h9.23v4.231h.128c1.285-2.434 4.424-5 9.105-5 9.744 0 11.544 6.413 11.544 14.75V58.81h-9.617V43.753c0-3.59-.066-8.209-5-8.209-5.007 0-5.776 3.911-5.776 7.95V58.81h-9.615V27.848z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/bluetickconsultants/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-instagram"
                    style={{ fontSize: "20px", color: "#0270D7" }}
                  ></i>
                </a>
              </li>
            </ul>
            <div className="footer-container-copyright">
              2023 © All rights reserved by Bluetick Consultants LLP
            </div>
          </div>
      </div>
   
  );
}

export default Footer