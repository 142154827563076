import React from 'react'
import Header from '../Header/Header';
import "./landing.scss"
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import LandingBanner from '../landingBanner/LandingBanner';

const cardData = [
    {
        title: "Comprehensive Code Parsing",
        text: "Automatically parses and processes all files in a specified path, specifically tailored for Python language."
    },
    {
        title: "Advanced Code Analysis",
        text: "Each file is analyzed independently, extracting code and storing it in a sophisticated vector database for quick access."
    },
    {
        title: "AI-Powered Query Handling",
        text: "Leverages a Language Model (LLM) for answering queries, offering in-depth, specific responses for code optimization and error handling."
    },
    {
        title: "Interactive Chat Interface",
        text: "Engage with a user-friendly chat interface to ask questions and receive instant AI-assisted guidance on your code."
    },
    // {
    //     title: "Real-Time Code Insights",
    //     text: "Gain insights on code efficiency, potential bugs, and optimization strategies in real time, directly from your coding environment."
    // },
]

const Landing = () => {
  return (
    <>
      <div className="container">
        <div className="container-hero">
          <div className="container-hero-left">
          <span className="container-hero-left-title">
              Talk-To-Code
            </span>
            <span className="container-hero-left-subheading">
              Your AI-Powered Code Assistant
            </span>
            <span className="container-hero-left-subtitle">
                Enhance your comprehension of code through AI-powered insights. Interact directly with your code to optimize, debug, and learn seamlessly while on the move.
            </span>
            <Link to="/repo" className="container-hero-left-button">
              <span>Get Started</span>
            </Link>
          </div>
          <div className="container-hero-right">
            <img
              className="container-hero-right-img"
              src={require("../Images/heroImg.png")}
              alt="heroImage"
            />
          </div>
        </div>
        <div className="container-info" id="guide">
          <span className="container-info-title">A quick guide to use Talk-To-Code</span>
          <div className="container-info-list">
            <span className="container-info-list-item">
              <span className="container-info-list-item-heading">Provide your GitHub repository: </span>
              Share either your public GitHub
              repository or the local path to your code.
            </span>
            <span className="container-info-list-item">
              <span className="container-info-list-item-heading">Interact with your own codebase:</span>
               Our advanced AI is ready to quickly
              provide precise and thorough responses to address your
              informational queries.
            </span>
            <span className="container-info-list-item">
              <span className="container-info-list-item-heading"> Receive code function wise debrief:</span>
              Gain a deeper understanding of
              your code, whether it's on a functional level or as a cohesive
              whole.
            </span>
          </div>
        </div>
        <div className="container-features" id="feature">
          <span className="container-features-title">Features</span>

          <div className="container-features-grid">
            {cardData.map((data, idx) => {
              return (
                <div className="container-features-grid-card">
                  <span className="container-features-grid-card-title">
                    {data.title}
                  </span>
                  <span className="container-features-grid-card-text">
                    {data.text}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <LandingBanner/>
        
      </div>
    </>
  );
}

export default Landing