import React, { useState, useEffect } from "react";

import BotIcon from "../Images/gpt.png"
export default function WelcomeMessage({ fetchMessage, send }) {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function loadMessage() {
      try {
        const msg = await fetchMessage();
        setLoading(false);
        if (msg) setMessage(msg);
      } catch (e) {
        console.log(e);
      }
    }
    loadMessage();
  }, [fetchMessage]);

  return (
    <div className="message-container">
      <div className="bot-message">
       <img src={BotIcon} className="icon" alt="botIcon" />
       { isLoading ? <span className="loading">Please wait..</span> : <div className="msg-container">
         {message.map((msg)=> {
          return (
            <span className="msg-container-button" onClick={() => send(msg)}>
              {msg}
            </span>
          );
         })}
        
        </div>}
      </div>
    </div>
  );
}
