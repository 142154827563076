import axios from "axios";
import Axios from "./instance";



export const loadRepo = async (payload) => {
   const res = await Axios.post('load_repo',payload)
   return res;
}

export const askQuestion = async(message)=>{
   const payload = {
     question: message,
     uuid: localStorage.getItem("repoId"),
   };
   const res = await Axios.post('ask_question',payload)
   console.log(res)
   return res;
}

export const getButtonText = async()=>{
   const payload = {
      programming_language: localStorage.getItem("programming_language"),
      uuid: localStorage.getItem("repoId"),

    };
   const res = await Axios.post("gen_question", payload)
   console.log(res)
   return res
}