import { askQuestion, getButtonText } from "../../api/apiCall";

const API = {
  GetChatbotResponse: async message => {
   const res = await getButtonText()
   return res.data.questions
  },
  GetBackendResponse: async message => {
    const res = await askQuestion(message)
    return res.data.answer.result
  }
};

export default API;
