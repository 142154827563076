import React, { useState, useEffect, useLayoutEffect } from "react";

import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";
import Messages from "./Messages";
import Input from "./Input";

import API from "./ChatbotAPI";

import "./chatbot.scss";
import { useNavigate } from "react-router-dom";
import WelcomeMessage from "./WelcomeMessage";

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const navigation = useNavigate()
  useLayoutEffect(()=> {
    if(!localStorage.getItem("repoId")){
      navigation("/repo")
    }
  })
  const handleSend = (msg) => {
    setMessages([])
   setTimeout(() => {
     send(msg);
   }, 0); 
  }
  useEffect(() => {
    async function loadWelcomeMessage() {      
      setMessages([
        <WelcomeMessage
          key="0"
          fetchMessage={async () => await API.GetChatbotResponse()}
          send={handleSend}
        />
      ]);
    }
    loadWelcomeMessage();
  }, []);

  const send = async text => {
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={async () => await API.GetBackendResponse(text)}
      />
    );
    setMessages(newMessages);
  };

  return (
    <div className="chatbotContainer">
    <div className="chatbotContainer-chatbot">
      <Messages messages={messages} />
      <Input onSend={send} />
    </div>
    </div>
  );
}

export default ChatBot;
