import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import Landing from './component/Landing/Landing';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import CheckRepo from './component/CheckRepo/CheckRepo';
import ChatBot from './component/chatbot';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PageWithHeaderAndFooter = ({children, showLogout = false, isHomePage = false})=> {
  const navigation = useNavigate()

  const logout = () => {
    localStorage.removeItem('repoId')
    localStorage.removeItem('programming_language')
    navigation("/")

  }
  return (
    <>
      <Header showLogout={showLogout} isHomePage={isHomePage} logoutFun={logout}/>
      {children}
      <Footer />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PageWithHeaderAndFooter isHomePage={true}>
              <Landing />
            </PageWithHeaderAndFooter>
          }
        />
        <Route
          path="/repo"
          element={
            <PageWithHeaderAndFooter>
              <CheckRepo />
            </PageWithHeaderAndFooter>
          }
        />
        <Route
          path="/chatbot"
          element={
            <PageWithHeaderAndFooter showLogout={true}>
              <ChatBot />
            </PageWithHeaderAndFooter>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
